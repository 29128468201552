export const roles = {
  srm: [
    'VIEW_INCIDENT',
    'VIEW_INCIDENTS',
    'VIEW_CONTRIBUTORS',
    'VIEW_ORGANISATIONS',
    'VIEW_QUESTIONNAIRES',
    'VIEW_CLOSURE',
    'VIEW_USER_DETAIL',
    'UPDATE_INSURER_PRIMARY_CONTACT',
    'VIEW_USERS_IN_CURRENT_ORG',
    'VIEW_INCIDENTS_PER_USER',
    'VIEW_CLOSED_CLOSURE',
    'VIEW_CLOSED_INCIDENT',
  ],
  insurer: [
    'VIEW_INCIDENT',
    'VIEW_INCIDENTS',
    'VIEW_ORGANISATIONS',
    'VIEW_USER_DETAIL',
    'VIEW_INCIDENT_MANAGER_ORGS',
    'UPDATE_INSURER_PRIMARY_CONTACT',
    'UPDATE_INCIDENT_MANAGER_ORG',
    'VIEW_USERS_IN_CURRENT_ORG',
    'VIEW_INCIDENTS_PER_USER',
    'VIEW_CLOSED_CLOSURE',
    'VIEW_CLOSED_INCIDENT',
  ],
  incidentManager: [
    'VIEW_INCIDENT',
    'VIEW_INCIDENTS',
    'VIEW_CONTRIBUTORS',
    'VIEW_ORGANISATIONS',
    'VIEW_USER_DETAIL',
    'VIEW_QUESTIONNAIRES',
    'VIEW_CLOSURE',
    'UPDATE_INCIDENT_MANGER_PRIMARY_CONTACT',
    'VIEW_USERS_IN_CURRENT_ORG',
    'VIEW_INCIDENTS_PER_USER',
    'VIEW_CLOSED_CLOSURE',
    'VIEW_CLOSED_INCIDENT',
  ],
  contributor: ['VIEW_INCIDENT_BY_CONTRIBUTOR', 'VIEW_CLOSURE'],
  primaryContact: [],
  noRole: [],
  user: [],
  admin: [],
};

const mockAuthorizeResponse = {
  user: {
    fullName: 'David Cheese',
    firstName: 'David',
    lastName: 'Cheese',
    emailAddress: 'davidcheese@s-rminform.com',
    telephone: '1234567890',
  },
  permissions: [
    'VIEW_INCIDENT',
    'VIEW_INCIDENTS',
    'VIEW_CONTRIBUTORS',
    'VIEW_ORGANISATIONS',
    'VIEW_QUESTIONNAIRES',
    'VIEW_CLOSURE',
    'VIEW_USER_DETAIL',
    'UPDATE_INSURER_PRIMARY_CONTACT',
    'VIEW_USERS_IN_CURRENT_ORG',
    'VIEW_INCIDENTS_PER_USER',
    'VIEW_INCIDENT_MANAGER_ORGS',
    'UPDATE_INCIDENT_MANAGER_ORG',
    'UPDATE_INCIDENT_MANAGER_PRIMARY_CONTACT',
    'VIEW_INCIDENT_BY_CONTRIBUTOR',
    'VIEW_CLOSED_CLOSURE',
    'VIEW_CLOSED_INCIDENT',
  ],
};

export default mockAuthorizeResponse;
