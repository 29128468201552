import {
  Incident,
  Organisation,
  OrganisationRole,
  OrganisationStatus,
} from '@/store/types/admin';

export interface PaginationRequest {
  pagination: {
    limit: number;
    offset: number;
  };
}

export interface PaginationResponse {
  total: number;
  limit: number;
  hasNext: boolean;
  hasPrev: boolean;
  offset: number;
  page: number;
  pages: number;
}

export interface IncidentsResponse {
  data: Incident[];
  pagination: PaginationResponse;
}

export const emptyPagination = {
  total: 0,
  limit: 0,
  offset: 0,
  hasNext: false,
  hasPrev: false,
  page: 0,
  pages: 0,
};
