import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { ChipVariant } from '@s-rm/react-ui-lib';
import {
  ContributorStatus,
  IncidentStatus,
  UserStatus,
} from '@/store/types/admin';

export const getIsValid = (
  formValues: Record<string, any>,
  requiredFields: string[]
) => {
  return requiredFields.every(
    (field) => !!formValues[field]?.toString().trim()
  );
};

export const getPercentageChipData = (percentage: number) => {
  if (percentage >= 90) {
    return {
      colorVariant: ChipVariant.Success,
      icon: CheckCircleOutlineOutlinedIcon,
    };
  } else if (percentage <= 40) {
    return {
      colorVariant: ChipVariant.Error,
      icon: HourglassEmptyOutlinedIcon,
    };
  } else {
    return {
      colorVariant: ChipVariant.Warning,
      icon: HourglassEmptyOutlinedIcon,
    };
  }
};

export const getContributorStatusChipVariant = (status: ContributorStatus) => {
  switch (status) {
    case ContributorStatus.Active:
      return ChipVariant.Success;
    case ContributorStatus.Suspended:
      return ChipVariant.Error;
    case ContributorStatus.Invited:
      return ChipVariant.Neutral;
    default:
      return ChipVariant.Neutral;
  }
};

export const getIncidentStatusChipVariant = (status: IncidentStatus) => {
  switch (status) {
    case IncidentStatus.Completed:
      return ChipVariant.Success;
    case IncidentStatus.InProgress:
      return ChipVariant.Primary;
    case IncidentStatus.NotStarted:
      return ChipVariant.Warning;
    default:
      return ChipVariant.Warning;
  }
};

export const getUserStatusChipVariant = (status: UserStatus) => {
  switch (status) {
    case UserStatus.Active:
      return ChipVariant.Success;
    case UserStatus.Invited:
      return ChipVariant.Transparent;
    case UserStatus.Suspended:
      return ChipVariant.Error;
    default:
      return ChipVariant.Success;
  }
};

export const getIncidentStatusChipLabel = (status: IncidentStatus) => {
  switch (status) {
    case IncidentStatus.Completed:
      return 'Completed';
    case IncidentStatus.InProgress:
      return 'In Progress';
    case IncidentStatus.NotStarted:
      return 'Not Started';
    default:
      return 'Not Started';
  }
};
