import React from 'react';
import TruncatedTextField from '@/components/TruncatedTextField/TruncatedTextField';
import { Row, Chip, Tooltip, Link, ButtonMenu } from '@s-rm/react-ui-lib';
import { Contributor, ContributorActions } from '@/store/types/admin';
import { ChipVariant } from '@s-rm/react-ui-lib/lib/components/Chip/types';
import { contributorStatusData, contributorRolesData } from '@/data/admin';
import { Box, IconButton } from '@mui/material';
import {
  getContributorStatusChipVariant,
  getPercentageChipData,
} from '../../utils/helpers';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export const getContributorsTableHeader = () => ({
  cells: [
    {
      id: 'email',
      content: 'Email',
      cellProps: { width: '25%' },
    },
    {
      id: 'name',
      content: 'Name',
      cellProps: { width: '20%' },
    },
    {
      id: 'status',
      content: 'Status',
    },
    {
      id: 'role',
      content: 'Role',
      cellProps: { width: '20%' },
    },
    {
      id: 'completionRate',
      content: 'Completion rate',
      cellProps: { width: '10%' },
    },
    {
      id: 'actions',
      content: 'Actions',
      cellProps: { sx: { textAlign: 'right', paddingRight: '40px' } },
    },
  ],
});

export const getContributorsTableRows = ({
  contributors,
}: {
  contributors: Contributor[];
}): Row[] => {
  return contributors.filter(Boolean).map((contributor) => {
    const completionRateData = getPercentageChipData(
      contributor.completionRate
    );
    return {
      id: contributor.id,
      cells: [
        {
          id: 'email',
          content: (
            <Tooltip
              title={contributor.email}
              placement='bottom-start'
              displayOnlyOnTruncation
            >
              <Link
                href={`/incident/${contributor.id}`}
                sx={{
                  textDecoration: 'none',
                  color: 'text.primary',
                  fontWeight: 400,
                  width: '100%',
                }}
              >
                <TruncatedTextField
                  value={contributor.email ?? '-'}
                  truncate={true}
                  noWrap={true}
                />
              </Link>
            </Tooltip>
          ),
        },
        {
          id: 'name',
          content: (
            <Tooltip
              title={contributor.name}
              placement='bottom-start'
              displayOnlyOnTruncation
            >
              <TruncatedTextField
                value={contributor.name ?? '-'}
                truncate={true}
                noWrap={true}
              />
            </Tooltip>
          ),
        },
        {
          id: 'status',
          content: (
            <Chip
              colorVariant={getContributorStatusChipVariant(contributor.status)}
              label={contributorStatusData[contributor.status].label}
              rounded
            />
          ),
        },
        {
          id: 'role',
          content: (
            <Box display='flex' gap={1}>
              {contributor.roles.map((role) => (
                <Chip
                  key={role}
                  colorVariant={'neutral' as ChipVariant}
                  label={contributorRolesData[role].abreviation}
                  rounded
                />
              ))}
            </Box>
          ),
        },
        {
          id: 'completionRate',
          content: (
            <Chip
              colorVariant={completionRateData.colorVariant}
              icon={<completionRateData.icon sx={{ height: '1rem' }} />}
              label={<>{`${contributor.completionRate}%`}</>}
              rounded
            />
          ),
        },
        {
          cellProps: { sx: { textAlign: 'right', paddingRight: '40px' } },
          id: 'actions',
          content: (
            <ButtonMenu
              providedButton={
                <IconButton>
                  <MoreVertIcon />
                </IconButton>
              }
              options={[
                {
                  label: 'Edit',
                  value: ContributorActions.EDIT_CONTRIBUTOR,
                  onClick: () => null,
                },
                {
                  label: 'Delete',
                  value: ContributorActions.DELETE_CONTRIBUTOR,
                  onClick: () => null,
                },
              ]}
              label='Select Action'
            />
          ),
        },
      ],
    };
  });
};
