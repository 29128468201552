import { AppThunk } from '../store';
import { setQuestions, setQuestionValue } from '../slices/questions';
import { RawInitialData } from '@/api/types/initialData';
import {
  setModules,
  addSelectedModules,
  setModuleGroups,
  addManuallySelectedModules,
  setStaticModuleIds,
} from '../slices/modules';
import {
  addSelectedIndicators,
  setIndicatorToCaseTypesMap,
  setIndicators,
  setSuggestedIndicators,
} from '../slices/indicators';
import {
  addSelectedCaseTypes,
  addSuggestedCaseTypes,
  setCaseTypes,
} from '../slices/caseTypes';
import { setTipAliasMap, setTips } from '../slices/tips';
import { setAdvice } from '../slices/advice';
import initialDataTransform from '@/api/transforms/initialData/initialDataTransform';
import runTriggersThunk from '../thunks/runTriggers';
import initModuleOnQuestionDisplayOrderThunk from '../thunks/initModuleOnQuestionDisplayOrder';
import {
  setClient,
  setClosureTime,
  setDescription,
  setDescriptionAtTimeOfSuggestIndicators,
  setQuestionnaireStartedAt,
  setIsCaseActive,
  setIsQuestionnaireActive,
  setProject,
  setTriggeredIntegrations,
  setIsDiagnosisEnabled,
  setIsNotesEnabled,
  setIsAddModulesEnabled,
  setQuestionnaireCompletedAt,
  setLabel,
  setQuestionnaireId,
  setProjectId,
} from '../slices/questionnaire';
import {
  setEntityModifications,
  setOutdatedEntities,
  setSessionInEdit,
} from '../slices/editor';
import { EditorSession } from '../types/editor';
import getModifiedEntities, {
  getOutdatedEntities,
} from '../utils/getModifiedFields';
import { setIntegrations } from '../slices/integrations';
import { getFeatureFlagFromStorage } from '@/components/FeatureFlags/utils/checkAndSetFeatureFlag';
import { setCurrentUser, setIsCaseValid } from '../slices/config';

/**
 * Transform the raw api data into usable state chunks and dispatch
 * to all required slices.
 *
 * If an existing case - convert state to how it was previously.
 */
export const hydrateStoreThunk =
  ({
    rawInitialData,
    sessionData,
  }: {
    rawInitialData: RawInitialData;
    sessionData: any;
  }): AppThunk =>
  (dispatch) => {
    const IS_USING_QUESTIONNAIRE_API = getFeatureFlagFromStorage(
      'IS_USING_QUESTIONNAIRE_API'
    );
    const {
      questions,
      modules,
      moduleGroups,
      indicators,
      indicatorToCaseTypesMap,
      caseTypes,
      tips,
      tipAliasMap,
      advice,
      integrations,
      user,
      unMergedEntities,
      editorSession,
      caseId: caseIdFromApi,
      project,
      client,
      questionValues,
      selectedCaseTypes,
      selectedIndicators,
      selectedModules,
      suggestedIndicators,
      suggestedCaseTypes,
      manuallySelectedModules,
      caseDescription: notes, // To be renamed by "notes" (from questionnaire.notes).
      isIntakeActive = false, // To be replaced by "isActive".
      triggeredIntegrations,
      // Please note: The field "isActive" is used interchangeably ATM for closure and questionnaire.
      // In the new API we shall use it to control the active state of the questionnaire,
      // and for the legacy case API its used to control the case closure active state.
      isActive,
      startedAt,
      completedAt,
      isDiagnosisEnabled,
      isNotesEnabled,
      isAddModulesEnabled,
      staticModuleIds,
      label,
      questionnaireId,
      projectId,
    } = initialDataTransform({ initialData: rawInitialData, sessionData });

    if (sessionData) {
      dispatch(setSessionInEdit(editorSession as EditorSession));
      dispatch(
        setEntityModifications(
          getModifiedEntities(editorSession as EditorSession)
        )
      );

      dispatch(
        setOutdatedEntities(
          getOutdatedEntities({
            // @ts-ignore
            edited: editorSession?.edited!,
            // @ts-ignore
            original: unMergedEntities,
          })
        )
      );
    }

    if (IS_USING_QUESTIONNAIRE_API) {
      dispatch(setProjectId(projectId));
      dispatch(setQuestionnaireId(questionnaireId!));
      dispatch(setLabel(label!));
      dispatch(setIsDiagnosisEnabled(isDiagnosisEnabled!));
      dispatch(setIsNotesEnabled(isNotesEnabled!));
      dispatch(setIsAddModulesEnabled(isAddModulesEnabled!));
      dispatch(setStaticModuleIds(staticModuleIds!));
      dispatch(setIsQuestionnaireActive(isActive!));
      dispatch(setQuestionnaireStartedAt(startedAt!));
      dispatch(setQuestionnaireCompletedAt(completedAt!));
    } else {
      dispatch(setIsQuestionnaireActive(isIntakeActive));
      dispatch(setQuestionnaireStartedAt(startedAt!));
      dispatch(setClosureTime(completedAt!));
      dispatch(setIsCaseActive(isActive));
    }

    dispatch(setIsCaseValid(!!caseIdFromApi));
    dispatch(setQuestions(questions));
    dispatch(setIntegrations(integrations));
    dispatch(setTriggeredIntegrations(triggeredIntegrations));
    dispatch(setTipAliasMap(tipAliasMap));
    dispatch(setCurrentUser(user));
    dispatch(setProject(project));
    dispatch(setClient(client));
    dispatch(setIndicators(indicators));
    dispatch(addSelectedIndicators(selectedIndicators));
    dispatch(setSuggestedIndicators(suggestedIndicators));
    dispatch(setCaseTypes(caseTypes));
    dispatch(setIndicatorToCaseTypesMap(indicatorToCaseTypesMap));
    dispatch(addSelectedCaseTypes(selectedCaseTypes));

    // Selected case types need to also be suggested in order to be displayed.
    // This might change.
    dispatch(addSuggestedCaseTypes(suggestedCaseTypes));

    dispatch(setModules(modules));
    dispatch(setModuleGroups(moduleGroups));
    dispatch(addSelectedModules({ moduleIds: selectedModules }));
    dispatch(addManuallySelectedModules(manuallySelectedModules));
    dispatch(setTips(tips));
    dispatch(setAdvice(advice));
    dispatch(setQuestionValue(questionValues));
    dispatch(
      initModuleOnQuestionDisplayOrderThunk(
        staticModuleIds?.length ? staticModuleIds : selectedModules
      )
    );
    dispatch(setDescription(notes));
    // Ensure the version we use to diff check is up to date from the start.
    dispatch(setDescriptionAtTimeOfSuggestIndicators(notes));

    // If we selected modules, this means we have presaved answers in store that
    // we can run the triggers for each root question which will recursively validate answers.
    // This ensures the relevent questions are initially displayed
    !!selectedModules ||
      (!!staticModuleIds && dispatch(runTriggersThunk(selectedModules)));
  };

export default hydrateStoreThunk;
