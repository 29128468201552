import {
  RawInitialData,
  TransformedInitialData,
} from '@/api/types/initialData';
import getflattenedStateEntities from './getFlattenedStateEntities';
import { getIndicatorToCaseTypesMap } from '../utils';
import { filterInvalidEntityIdLinks } from './filterInvalidIdLinks';
import getModulesByCaseTypesFromApi from '../utils/getModulesByCaseTypesFromApi';
import adaptApiDataEntities from './adaptApiDataEntities';
import getSugestedCaseTypeFromIndicators from '@/store/utils/getSuggestedCaseTypeFromIndicators';
import getSessionEntities from '../utils/getSessionEntities';
import mergeSessionEntities from '../utils/mergeSessionEntities';
import { formatMultiSelectAnswers } from '../utils/formatAnswers';
import getSessionTransform from '../editor/getSessionTransform';
import createTipAliases from '../utils/createTipAliases';
import { getFeatureFlagFromStorage } from '@/components/FeatureFlags/utils/checkAndSetFeatureFlag';

/**
 * Orchestrates the transformation of the raw initial data from the API
 * into the format expected by the store. Should api data change, this is
 * the only place that needs to be updated.
 *
 * The transformation is done in two steps:
 * 1. The raw data is adapted to the format expected by the store.
 * 2. The adapted data is flattened into the format expected by the store.
 */
const initialDataTransform = ({
  initialData,
  sessionData,
}: {
  initialData: RawInitialData;
  sessionData?: any;
}): TransformedInitialData => {
  const sessionEntities = getSessionEntities(sessionData);
  const editorSession = sessionData && getSessionTransform(sessionData);
  const IS_USING_QUESTIONNAIRE_API = getFeatureFlagFromStorage(
    'IS_USING_QUESTIONNAIRE_API'
  );

  // Used to reference original state of entities prior to any session merge.
  // curently used in the editor to determine if an entity is outdated.
  const unMergedEntities = adaptApiDataEntities({
    questions: initialData.getAllQuestions,
    caseTypes: initialData.getAllCaseTypes,
    indicators: initialData.getAllIndicators,
    modules: initialData.getAllModules,
    moduleGroups: initialData.getAllModuleGroups,
    tips: initialData.getAllTips,
    advice: initialData.getAllAdvice,
    caseEntity: initialData.getCase,
    description: initialData.getDescription,
    user: initialData.getUserDetails,
    integrations: initialData.getAllIntegrations,
    questionnaireEntity: initialData.getQuestionnaire,
  });

  const mergedEntities = {
    questions: mergeSessionEntities(
      initialData.getAllQuestions,
      // @ts-ignore
      sessionEntities?.questions
    ),
    caseTypes: mergeSessionEntities(
      initialData.getAllCaseTypes,
      // @ts-ignore
      sessionEntities?.caseTypes
    ),
    indicators: mergeSessionEntities(
      initialData.getAllIndicators,
      // @ts-ignore
      sessionEntities?.indicators
    ),
    modules: mergeSessionEntities(
      initialData.getAllModules,
      // @ts-ignore
      sessionEntities?.modules
    ),
    moduleGroups: mergeSessionEntities(
      initialData.getAllModuleGroups,
      // @ts-ignore
      sessionEntities?.moduleGroups
    ),
    tips: mergeSessionEntities(
      initialData.getAllTips,
      // @ts-ignore
      sessionEntities?.tips
    ),
    advice: mergeSessionEntities(
      initialData.getAllAdvice,
      // @ts-ignore
      sessionEntities?.advice
    ),
  };

  const adaptedEntities = adaptApiDataEntities({
    ...mergedEntities,
    caseEntity: initialData.getCase,
    description: initialData.getDescription,
    user: initialData.getUserDetails,
    integrations: initialData.getAllIntegrations,
    questionnaireEntity: initialData.getQuestionnaire,
  });

  const sanitizedEntities = filterInvalidEntityIdLinks(adaptedEntities);

  // Create alias id's for any duplicate tip triggers.
  // Mutate the trigger componentId to this alias Id.
  // Return a map of alias id's to original tip id's.
  const tipAliasMap = createTipAliases({
    questions: sanitizedEntities.questions,
  });

  // Create our map of which indicators are linked to which case types.
  const indicatorToCaseTypesMap = getIndicatorToCaseTypesMap(
    sanitizedEntities.caseTypes || []
  );

  // Remove indicators that are not linked to at least one case type.
  const inUseIndicators = sanitizedEntities.indicators.filter(
    ({ id }) => indicatorToCaseTypesMap[id]
  );

  // Any entities that can be flattened should be flattened here.
  const flattenedEntities = getflattenedStateEntities({
    ...sanitizedEntities,
    indicators: inUseIndicators,
  });

  const suggestedCaseTypes = getSugestedCaseTypeFromIndicators({
    indicators:
      sanitizedEntities[
        IS_USING_QUESTIONNAIRE_API ? 'questionnaireEntity' : 'caseEntity'
      ]!.selectedIndicators,
    indicatorCaseTypesMap: indicatorToCaseTypesMap,
  });

  const formattedAnswers = formatMultiSelectAnswers({
    answers:
      sanitizedEntities[
        IS_USING_QUESTIONNAIRE_API ? 'questionnaireEntity' : 'caseEntity'
      ]!.questionValues,
    questions: flattenedEntities.questions.entities,
  });

  //@ts-ignore
  return {
    ...sanitizedEntities[
      IS_USING_QUESTIONNAIRE_API ? 'questionnaireEntity' : 'caseEntity'
    ],
    tipAliasMap,
    questionValues: formattedAnswers,
    ...flattenedEntities,
    indicatorToCaseTypesMap,
    suggestedIndicators: sanitizedEntities.description.suggestedIndicators,
    caseDescription: sanitizedEntities.description.text, // Note: this will be replaced with questionnaire.notes
    selectedModules: [
      ...getModulesByCaseTypesFromApi({
        caseTypes: sanitizedEntities.caseTypes,
        selectedCaseTypes:
          sanitizedEntities[
            IS_USING_QUESTIONNAIRE_API ? 'questionnaireEntity' : 'caseEntity'
          ]!.selectedCaseTypes,
      }),
      ...sanitizedEntities[
        IS_USING_QUESTIONNAIRE_API ? 'questionnaireEntity' : 'caseEntity'
      ]!.manuallySelectedModules,
    ],
    suggestedCaseTypes,
    user: adaptedEntities.user,
    editorSession,
    unMergedEntities,
  };
};

export default initialDataTransform;
