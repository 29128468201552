import { useAppDispatch, useAppSelector } from '@/hooks';
import { Box, Container } from '@mui/material';
import { FlexContainer, InfoBarContainer } from './styled';
import NavButton from './NavButton';
import { selectIsCaseActive } from '@/store/slices/questionnaire';
import {
  selectIsEditMode,
  setIsClosureSummaryDialogOpen,
  setIsConfirmingCaseClosure,
} from '@/store/slices/ui';
import EditModeContent from './EditModeContent';
import GlobalQuestionCounter from '../RequiredQuestionsCounter/GlobalQuestionCounter';
import { Button } from '@s-rm/react-ui-lib';
import useCaseClosure from '@/hooks/useCaseClosure';

const InfoBar = () => {
  const dispatch = useAppDispatch();

  const isEditMode = useAppSelector(selectIsEditMode);
  const { isCaseClosureRoute } = useCaseClosure();
  const isCaseActive = useAppSelector(selectIsCaseActive);

  const handleClickSubmitCase = () =>
    dispatch(setIsConfirmingCaseClosure(true));
  const handleClickClosurePreview = () =>
    dispatch(setIsClosureSummaryDialogOpen(true));

  return (
    <Box py={5} data-testid='info-bar'>
      <Container maxWidth='xl'>
        <FlexContainer>
          {!!isEditMode && <EditModeContent />}
          {!isEditMode && (
            <>
              <Box ml='auto' mr={2}>
                <GlobalQuestionCounter />
              </Box>
              {isCaseClosureRoute ? (
                <Box display='flex' gap={2}>
                  <Button
                    variant='outlined'
                    onClick={handleClickClosurePreview}
                    data-testid='infobarpreview-btn'
                  >
                    Preview
                  </Button>
                  <Button
                    disabled={!isCaseActive}
                    variant='contained'
                    onClick={handleClickSubmitCase}
                    data-testid={
                      isCaseActive
                        ? 'infobar-close-case-btn'
                        : 'infobar-case-closed-btn'
                    }
                  >
                    {isCaseActive ? 'Submit Closure' : 'Closure Submitted'}
                  </Button>
                </Box>
              ) : (
                <NavButton />
              )}
            </>
          )}
        </FlexContainer>
      </Container>
    </Box>
  );
};

export default InfoBar;
