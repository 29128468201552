import {
  setIsLoadingContributors,
  selectContributorsFilters,
  updateContributorFilters,
  setContributors,
} from '@/store/slices/admin/admin';
import { AppThunk } from '../../store';

import getFormattedContributors from '@/api/transforms/admin/getFormattedContributors';
import fetchContributors from '@/api/admin/contributors/fetchContributors';

const fetchContributorsThunk =
  ({ incidentId }: { incidentId: string }): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setIsLoadingContributors(true));
    try {
      const filters = selectContributorsFilters(getState());

      const {
        data,
        pagination: { total, limit },
      } = await fetchContributors({
        filters,
        incidentId,
      });

      dispatch(setContributors(getFormattedContributors(data)));

      dispatch(
        updateContributorFilters({
          pagination: {
            offset: filters.pagination?.offset || 0,
            limit,
            total,
          },
        })
      );
    } catch (error) {
    } finally {
      dispatch(setIsLoadingContributors(false));
    }
  };

export default fetchContributorsThunk;
