import getQuestionnairesForProject from '@/api/queries/getQuestionnairesForProject';
import { useAppSelector } from '@/hooks';
import {
  selectProjectId,
  selectQuestionnaireId,
} from '@/store/slices/questionnaire';
import { getIsClosure, getIsIntake } from '@/utils/helpers';
import { ButtonMenu } from '@s-rm/react-ui-lib';
import { useEffect, useState } from 'react';

const QuestionnaireSelector = () => {
  const projectId = useAppSelector(selectProjectId);
  const currentQuestionnaire = useAppSelector(selectQuestionnaireId);
  const [questionnaireOptions, setQuestionnaireOptions] = useState<
    {
      label: string;
      value: string;
      onClick: () => void;
    }[]
  >([]);

  const fetchQuestionnaires = async () => {
    const { data } = await getQuestionnairesForProject(projectId);

    const options = data.map((questionnaire) => {
      const isIntake = getIsIntake(questionnaire.label);
      const isClosure = getIsClosure(questionnaire.label);

      let questionnaireName = questionnaire.label;
      if (isIntake) {
        questionnaireName = 'Intake';
      }
      if (isClosure) {
        questionnaireName = 'Closure';
      }

      return {
        label: `Project: ${questionnaire.projectName} - ${questionnaireName}`,
        value: questionnaire.id,
        onClick: () =>
          (window.location.href = `/questionnaire/${questionnaire.id}`),
      };
    });

    setQuestionnaireOptions(options);
  };

  useEffect(() => {
    fetchQuestionnaires();
  }, []);
  return (
    <ButtonMenu
      value={currentQuestionnaire}
      options={questionnaireOptions}
      buttonType='chip'
    />
  );
};

export default QuestionnaireSelector;
