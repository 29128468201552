import { ReactNode } from 'react';

export enum SnackbarTypes {
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
  Info = 'info',
}

export interface PendingInterceptAction {
  action: string;
  payload: any;
}

export interface UiSlice {
  scrollPositions: {
    [key: string]: number;
  };
  printableSummaryStatus: {
    isOpen: boolean;
    isAutoCopy: boolean;
  };
  snackbarNotification: {
    message: ReactNode;
    action: ReactNode;
    type: SnackbarTypes;
    disableAutoHide: boolean;
  };
  refreshDialogStatus: {
    isOpen: boolean;
  };
  descriptionPopoutWindowStatus: {
    isOpen: boolean;
  };
  suggestedIndicatorsStatus: {
    isLoading: boolean;
  };
  isRefreshingDisplay: boolean;
  isEditMode: boolean;
  suggestedAnswersFetchingStatus: {
    [key: string]: {
      isLoading?: boolean;
      hasError?: boolean;
      hasSucceeded?: boolean;
    };
  };
  hasRequestedSuggestedAnswers: boolean;
  hasQuestionnaireBeenCleared: boolean;
  hasDismissedQuestionnaireTimeDialog: boolean;
  caseClosureId: string | null;
  isOpenClearCaseDialog: boolean;
  isDescriptionVisible: boolean;
  isConfirmingCaseClosure: boolean;
  isConfirmingFinishQuestionnaire: boolean;
  isConfirmingReopenCaseClosure: boolean;
  isConfirmingReopenQuestionnaire: boolean;
  isClosureSummaryDialogOpen: boolean;
  pendingInterceptAction: PendingInterceptAction[];
  isOpenQuestionnaireTimeDialog: boolean;
  activePath: string;
  isOpenCaseClosureTimeDialog: boolean;
  hasDismissedCaseClosureTimeDialog: boolean;
  hasTriggeredIntegrationDialogOpen: {
    isOpen: boolean;
    integrationName: string;
  };
  isOpenCreateIncidentDialog: boolean;
  isContributorsListDialogOpen: boolean;
  isCreatingIncident: boolean;
  isCreatingUser: boolean;
  isOpenCreateUserFormDialog: boolean;
  isOpenCreateOrganisationDialog: boolean;
  isCreatingOrganisation: boolean;
  isAddContributorModalOpen: boolean;
  isCreateUserModalOpen: boolean;
  organisationDialog: {
    isOpen: boolean;
    isEditMode: boolean;
    organisationId?: string;
  };
}
